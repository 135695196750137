import type { Effect } from '@/models/connect';
import type { Login, RentedUnit, ShowingSearchResult } from '@propify-tenant-client/services';
import { AccountService, ShowingService, UnitService } from '@propify-tenant-client/services';
import type { Reducer } from 'redux';

export interface AccountModelState {
  banned: boolean;
  login: Login | undefined;
  showings: ShowingSearchResult[];
  rentedUnit: RentedUnit | undefined;
}

interface AccountModelType {
  namespace: string;
  state: AccountModelState;
  effects: {
    me: Effect;
    fetchUpcomingShowings: Effect;
    fetchPastShowings: Effect;
    updateAccount: Effect;
    fetchRentedUnit: Effect;
  };
  reducers: {
    clear: Reducer<AccountModelState>;
    saveLoginState: Reducer<AccountModelState>;
    saveUpcomingShowingsState: Reducer<AccountModelState>;
    savePastShowingsState: Reducer<AccountModelState>;
    saveRentedUnit: Reducer<AccountModelState>;
    toggleAccountBanned: Reducer<AccountModelState>;
  };
}

const AccountModel: AccountModelType = {
  namespace: 'account',

  state: {
    banned: false,
    login: undefined,
    showings: [],
    rentedUnit: undefined,
  },

  effects: {
    *me(_, { call, put }) {
      const response = (yield call(AccountService.me)) as Login;
      yield put({
        type: 'saveLoginState',
        payload: response,
      });
    },
    *fetchUpcomingShowings(_, { call, put }) {
      // @ts-ignore
      const response = yield call(ShowingService.findUpcomingForAccount);
      yield put({
        type: 'saveUpcomingShowingsState',
        payload: response,
      });
    },
    *fetchPastShowings(_, { call, put }) {
      // @ts-ignore
      const response = yield call(ShowingService.findPastsForAccount);
      yield put({
        type: 'savePastShowingsState',
        payload: response,
      });
    },
    *updateAccount({ payload }, { put }) {
      yield put({
        type: 'saveLoginState',
        payload,
      });
    },
    *fetchRentedUnit(_, { call, put }) {
      // @ts-ignore
      const response = yield call(UnitService.getRentedUnit);

      yield put({
        type: 'saveRentedUnit',
        payload: response,
      });
    },
  },
  reducers: {
    clear(): AccountModelState {
      return {
        banned: false,
        login: undefined,
        showings: [],
        rentedUnit: undefined,
      };
    },
    saveLoginState(state, action) {
      return {
        ...(state as AccountModelState),
        login: action.payload,
      };
    },
    saveUpcomingShowingsState(state, action) {
      return {
        ...(state as AccountModelState),
        showings: action.payload || [],
      };
    },
    savePastShowingsState(state, action) {
      return {
        ...(state as AccountModelState),
        showings: action.payload || [],
      };
    },
    saveRentedUnit(state, action) {
      return {
        ...(state as AccountModelState),
        rentedUnit: action.payload[0],
      };
    },
    toggleAccountBanned(state, action) {
      return {
        ...(state as AccountModelState),
        banned: !!action.payload,
      };
    },
  },
};

export default AccountModel;
