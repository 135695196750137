import type { Effect } from '@/models/connect';
import type { Unit } from '@propify-tenant-client/services';
import { UnitService } from '@propify-tenant-client/services';
import type { Reducer } from 'redux';

interface UnitModelState {
  unit: Partial<Unit>;
}

interface UnitModelType {
  namespace: string;
  state: UnitModelState;
  effects: {
    findById: Effect;
  };
  reducers: {
    saveUnitState: Reducer<UnitModelState>;
  };
}

export const getUnit = (state: any) => state.unit.unit;

const UnitModel: UnitModelType = {
  namespace: 'unit',

  state: {
    unit: {},
  },

  effects: {
    *findById({ payload }, { call, put }) {
      yield put({
        type: 'saveUnitState',
        payload: {},
      });

      // @ts-ignore
      const response = yield call(UnitService.findById, payload, true);

      yield put({
        type: 'saveUnitState',
        payload: response,
      });
    },
  },
  reducers: {
    saveUnitState(state, action) {
      return {
        ...(state as UnitModelState),
        unit: action.payload || {},
      };
    },
  },
};

export default UnitModel;
