import type { Effect } from '@/models/connect';
import { getHistory, securityRedirect } from '@propify-tenant-client/common';
import { handleError, IdentityService } from '@propify-tenant-client/services';

interface PersonaModelType {
  namespace: string;
  state: Record<string, never>;
  effects: {
    validationComplete: Effect;
    validationFailed: Effect;
  };
  reducers: Record<string, never>;
}

const PersonaVerificationModel: PersonaModelType = {
  namespace: 'personaVerification',
  state: {},
  effects: {
    *validationComplete({ payload }, { call, put, take }) {
      try {
        yield call(IdentityService.submitInquiryId, payload.inquiryId);
        yield put({ type: 'account/me' });
        yield take('account/saveLoginState');
        getHistory().replace(securityRedirect() || '/account');
      } catch (error) {
        handleError(error, { displayToast: false });
        payload.err();
      }
    },
    *validationFailed({ payload }, { call, put, take }) {
      yield call(IdentityService.submitInquiryId, payload);
      yield put({ type: 'account/me' });
      yield take('account/saveLoginState');
    },
  },
  reducers: {},
};

export default PersonaVerificationModel;
