import type { Effect } from '@/models/connect';
import type { Enum } from '@propify-tenant-client/services';
import { EnumerationService } from '@propify-tenant-client/services';
import type { Reducer } from 'redux';

export interface EnumsModelStateType {
  showingStatusMap: Record<string, string>;
}

interface EnumsModelType {
  namespace: string;
  state: EnumsModelStateType;
  effects: {
    getShowingStatus: Effect;
  };
  reducers: {
    updateShowingStatusMap: Reducer<EnumsModelStateType>;
  };
}

const Model: EnumsModelType = {
  namespace: 'enums',

  state: {
    showingStatusMap: {},
  },

  effects: {
    *getShowingStatus(_, { call, put }) {
      // @ts-ignore
      const status = (yield call(EnumerationService.getEnums, 'showing-status')) as Enum[];
      const statusMap = status.reduce<Record<string, string>>((memo, item) => {
        // eslint-disable-next-line no-param-reassign
        memo[item.value] = item.displayName;
        return memo;
      }, {});

      yield put({
        type: 'updateShowingStatusMap',
        payload: statusMap,
      });
    },
  },

  reducers: {
    updateShowingStatusMap(state, { payload }) {
      return {
        ...state,
        showingStatusMap: payload,
      };
    },
  },
};

export default Model;
