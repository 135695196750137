import AccountModel from '@/models/account';
import type { Model } from '@/models/connect';
import EnumsModel from '@/models/enums';
import LoginModel from '@/models/login';
import SDKModel from '@/models/sdk';
import SystemModel from '@/models/system';
import UnitModel from '@/models/unit';
import EmailVerificationModel from '@/pages/user/verification/email/model';
import PersonaVerificationModel from '@/pages/user/verification/persona/model';
import SMSModel from '@/pages/user/verification/sms/model';
import { initialize } from '@/utils/store';
import { AccountStatus, getResponseText, handleError } from '@propify-tenant-client/services';
import createSagaMiddleware from '@redux-saga/core';
import { configureStore } from '@reduxjs/toolkit';
import { persistReducer, persistStore } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

const models: Model[] = [
  UnitModel,
  SystemModel,
  SDKModel,
  EnumsModel,
  AccountModel,
  LoginModel,
  SMSModel,
  PersonaVerificationModel,
  EmailVerificationModel,
];

const { rootReducer, sagas } = initialize(models);

const sagaMiddleWare = createSagaMiddleware({
  onError(error: Error) {
    const e = error as any;
    e.preventDefault?.();

    try {
      if (e.response && e.response.text) {
        getResponseText(e.response).then((messageData) => {
          if (messageData.message === AccountStatus.BANNED) {
            // eslint-disable-next-line @typescript-eslint/no-use-before-define
            store.dispatch({
              type: 'account/toggleAccountBanned',
              payload: true,
            });
          }
        });
      }
    } catch (e2) {
      handleError(e2, { displayToast: false });
    }
  },
});

const persistedReducer = persistReducer(
  {
    key: 'nhrapp',
    storage,
  },
  rootReducer,
);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: [sagaMiddleWare],
});

sagas.forEach((saga) => sagaMiddleWare.run(saga));

export const persistor = persistStore(store);
