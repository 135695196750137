import ErrorSvg from '@/assets/500.svg?react';
import { Button } from '@propify-tenant-client/common';
import { Colors } from '@propify/components';
import type { FC } from 'react';
import { useNavigate } from 'react-router';
import { Box, makeStyles, Typography } from '../utils/material';

const useStyles = makeStyles({
  title: {
    textAlign: 'center',
    marginBottom: '12px',
  },
  subtitle: {
    textAlign: 'center',
    color: Colors.disabled,
    marginBottom: '32px',
  },
});

const ErrorPage: FC = () => {
  const classes = useStyles();
  const navigate = useNavigate();

  const handleBackHome = () => navigate('/');

  return (
    <Box display="flex" flexDirection="column" alignItems="center" pt={3}>
      <ErrorSvg />
      <Box flexDirection="column" alignItems="center" mt={3}>
        <Typography variant="h5" className={classes.title}>
          Unexpected Error
        </Typography>
        <Typography variant="body2" className={classes.subtitle}>
          Sorry, the page you visited has thrown an unexpected error.
        </Typography>
        <Box textAlign="center">
          <Button color="primary" onClick={handleBackHome}>
            Back Home
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default ErrorPage;
